import React from 'react';

import * as styles from './hero.module.css';

const Hero = () => (
  <div className={styles.hero}>
    <div>
      <h1 className={styles.heroName}>Tomek Nieżurawski</h1>
      <h2 className={styles.heroLine}>
        <span>Connects humans and machines</span>
        <br />
        <small>via awesome interfaces</small>
      </h2>
    </div>
  </div>
);

export default Hero;
