import React from 'react';

import Layout from '../components/layout';
import Hero from '../components/Hero/hero';
import SEO from '../components/seo';

import PostCard from '../components/PostCard/post-card';
import ogImg from '../images/og/home-v3.png';

const IndexPage = () => (
  <Layout>
    <SEO imageUrl={ogImg} />
    <Hero />

    <div className="container">
      <h2 className="pageTitle">Blog posts</h2>

      <div className="mb-55">
        <PostCard
          title="Benchmarking GraphQL solutions in the JS/TS landscape"
          date="08.12.2024"
          routeName="/posts/benchmarking-graphql-solutions-in-the-js-ts-landscape"
          tags={['GraphQL', 'JavaScript']}
        >
          Not all GraphQL stacks are equal when it comes to performance. Let's
          find out who's the fastest one!
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Interactive Radial Gauge"
          date="12.06.2023"
          routeName="/posts/interactive-radial-gauge-in-react"
          tags={['JavaScript', 'Components', 'Fun']}
        >
          Implementing a radial gauge and making it interactive is not an easy
          task... but it is oddly satisfying! Let's dive into it together.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Sorting colors in JavaScript"
          date="22.06.2021"
          routeName="/posts/sorting-colors-in-js"
          tags={['JavaScript', 'Algorithms']}
        >
          Sorting colors is a surprisingly hard thing to do. Join me on a
          journey on how to put colors in order that would please our human
          eyes.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Anchors for headings in MDX"
          date="07.02.2021"
          routeName="/posts/anchors-for-headings-in-mdx"
          tags={['MDX']}
        >
          A quick tutorial on how to add anchors to heading in MDX. This is a
          cool little feature and it's great to see how easy it is to implement
          in MDX.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Throttle vs Debounce on real examples"
          date="21.01.2021"
          routeName="/posts/throttle-vs-debounce-on-real-examples"
          tags={['JavaScript', 'UI']}
        >
          Throttle and debounce let you save some resources and increase the
          performance of your app. Learn about differences and use cases. There
          are also cases where you should not use them.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Highlight text in JavaScript"
          date="07.01.2021"
          routeName="/posts/highlight-text-in-javascript"
          tags={['HTML', 'JavaScript']}
        >
          Learn how to add dynamic highlighting to your search. It could be
          applied as a search on a page functionality or used to show users why
          some of the search results are on the screen by highlighting the
          matching parts.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Search with typo tolerance"
          date="02.01.2021"
          routeName="/posts/search-with-typo-tolerance"
          tags={['UX', 'Algorithms']}
        >
          Too often devs are lazy and show a "No results" page when users
          mistype something. Surprisingly it's not that difficult to provide a
          simple typo-tolerant search mechanism.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Useful setup that I always use when starting a new project"
          date="16.11.2020"
          routeName="/posts/powerful-start"
          tags={['Setup', 'Tools']}
        >
          A bunch of actions you should set when starting a new project that
          will set you in the right direction and save time in the long term.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Effective titles and more"
          date="06.11.2020"
          routeName="/posts/effective-titles-and-more"
          tags={['UX', 'Tricks']}
        >
          The title element offers more than you think. Let's dive into basics
          and some crazy stuff you can do with{' '}
          <span className="code">&lt;title&gt;</span>. It can extend user
          experience but can be also overused.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="If there is an input, there should be a form"
          date="05.10.2020"
          routeName="/posts/input-and-form"
          tags={['UX', 'Accessibility']}
        >
          I often see in Single Page apps a situation where someone uses just an{' '}
          <span className="code">&lt;input&gt;</span> field. Sometimes in the
          accompaniment of <span className="code">&lt;label&gt;</span> if you
          happen to work with a pro 🌟. It feels that when we gained control of
          inputs with two-way binding and we started handling{' '}
          <span className="code">onclick</span> events on buttons with our fancy
          frameworks, we forgot the old way of doing things.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Content Curation is going to be a job and how Content Marketing killed
          the web"
          date="03.10.2020"
          routeName="/posts/content-curation-and-marketing"
          tags={['Idea']}
        >
          We live in times where everyone fights for attention. It wasn't always
          like that. I remember the beginning of the web where you really had to
          work hard to find valuable information...
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Dynamic partiallyActive Link in Gatsby"
          date="29.09.2020"
          routeName="/posts/dynamic-partially-active-link-in-gatsby"
          tags={['Code', 'Gatsby']}
        >
          Gatsby offers you to use <span className="code">activeClassName</span>{' '}
          where you can adjust the style of your link to indicate that a user is
          "under" that module or section of your page. In other words, it helps
          people to understand where they are.
        </PostCard>
      </div>

      <h2 className="pageTitle">Guest posts</h2>

      <p className="guest-posts-description">
        I prefer to write on my own blog (platform) because I have no
        limitations but from time to time I write some posts where I mostly
        share ideas. I'd describe them as "text posts" in opposite to
        interactive posts you can expect to see here. Anyway, it's still worth
        reading them. Enjoy!
      </p>

      <div className="mb-55">
        <PostCard
          title="Feature flags: bad practices"
          date="05.06.2021"
          href="https://nothingventured.rocks/feature-flags-bad-practices-6f4a61842ef2"
          tags={['Feature Flags', 'Product Management']}
        >
          Feature flags are a critical part of what allows Phorest to ship code
          to users, fast, safely, and with high confidence. But there are some
          traps you should be aware of as highlighted in this post.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="MJML for email is what React Native is for mobile"
          date="20.04.2021"
          href="https://nothingventured.rocks/mjml-for-email-is-what-react-native-is-for-mobile-9579612b57e8"
          tags={['Email', 'MJML']}
        >
          React Native has changed the way we do mobile apps. I absolutely love
          it. But there is another technology that deserves an award. In this
          article I present MJML and how folks at Phorest use it.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Alignment meetings"
          date="11.01.2021"
          href="https://nothingventured.rocks/alignment-meetings-acce06fefb62"
          tags={['Software development']}
        >
          Folks at Phorest are getting very religious about it but they love our
          bi-weekly Alignment meetings so much that I had to share a few words
          about it.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="We chose Ember in 2015 and it is still a good decision in 2020"
          date="17.11.2020"
          href="https://nothingventured.rocks/we-chose-ember-in-2015-and-it-is-still-a-good-decision-in-2020-c2eb1502a20a"
          tags={['Ember', 'Technology']}
        >
          ... and will be in 2021 in my opinion. I personally like to work with
          Ember and React (especially React Native). This blog is written in
          Gatsby (React). I worked a lot with Angular. But still, I know you
          choose the tool for a job. Ember, even with a shrinking community, was
          a great choice for Phorest.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="Create Ember Addon with command line support"
          date="05.06.2018"
          href="https://tomekdev.medium.com/create-ember-addon-with-command-line-support-baa1fae05ad9"
          tags={['CLI', 'Ember']}
        >
          Tutorial on how I built{' '}
          <a
            href="https://github.com/vastec/ember-unused-components"
            target="_blank"
            rel="noreferrer"
          >
            ember-unused-components
          </a>{' '}
          addon that scans repository and tells Ember devs what components are
          not used. It shows how we can add command line support.
        </PostCard>
      </div>

      <div className="mb-55">
        <PostCard
          title="How we speed up testing and building process of our Ember app at Brandnew? ~2.5x faster!"
          date="12.07.2017"
          href="https://tomekdev.medium.com/how-we-speed-up-testing-and-building-process-of-our-ember-app-at-brandnew-2-5x-faster-299dd4995a97"
          tags={['CI', 'Ember']}
        >
          A journey on how we were able to improve running an Ember application
          building process ~2.5x faster on CI.
        </PostCard>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
